// .textclass-contetn {
//     background: none;
//     border: 2px dashed #c8d6d9;
//     box-shadow: none;
//     color: #c8d6d9;
//     width: 100%;
//     margin-right: 10px;
//     border-radius: 5px;
// }
html {
    overflow-y: scroll;
    overflow-x:hidden ;
    max-width: 100dvw;
    scrollbar-gutter: stable;

  }
.selected-test {
    box-sizing: border-box;
}

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
}

.step-banner {
    padding: 12px 16px;
    background: #e9f0f1;
    border-radius: 4px;
    margin-bottom: 12px;
}

.result-maintain {
    background-color: #fff;
    padding: 15px;
}

.login-right-section {
    display: flex;
    align-items: center;
    text-align: center;
    p {
        margin-bottom: 0;
    }
}

.card-area-dashboard {
    p.card-text {
        font-size: 26px;
        color: #000;
    }
}

.chart-wrapper {
    margin: auto;
    max-width: 400px !important;
    margin-top: 35px !important;
}

.table-btn {
    vertical-align: middle;
}

.dropdown-item {
    cursor: pointer;
}
.dropdown-item:active{
    color: rgb(44 56 74 / 95%);
    background-color: transparent
}

.table-light {
    th {
        white-space: nowrap;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.date-pic {
    width: 100%;
    border: 1px solid #d8dbe0;
    box-shadow: none;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
}

li.breadcrumb-item {
    a {
        text-decoration: none;
    }
}

.notification-card{
 width: 20rem;
 font-size: 12px;
 min-height: 8rem;
 max-height: 25rem;
} 
.notification-card-item{
margin: 2px  0;
border: 0.5px solid rgb(207, 207, 207);
border-radius: 4px;
cursor: text;
}

.notification-card-item.unread{
    background-color: rgb(207, 207, 207);
}
.notification-card-item.unread:hover{
    background-color: #ebedef;
}
.pagination-item{
    cursor: pointer;
}
.new-button{
    background-color: white;
}
.new-button.danger{
    border: 1px solid #e55353;
    color: #e55353;
}
.new-button.danger:hover{
    background-color: #e55353;
    color: white;
}
.new-button.success{
    border: 1px solid #2eb85c;
    color: #2eb85c;
}
.new-button.success:hover{
    background-color: #2eb85c;
    color: white;
}
.nav.centernav{
    justify-content: center;
}
@media only screen and (max-width: 1201px) {
    .g-recaptcha {
        transform:scale(0.77);
        transform-origin:0 0;
    }
}

.header-profile-image{
    width: 2.5rem;
    height: 2.5rem,
}
@media only screen and (max-width: 600px) {
    .header-profile-image {
        width: 1.95rem;
        height: 1.95rem,
    }
}